<!--  -->
<template>
	<div class="cell-courseList" @click="toToDetail">
		<div class="cover-wrap">
			<img :src="courseInfo.oimage" alt="" class="cover" />
			<div class="status" v-if="courseInfo.labelname">{{ courseInfo.labelname }}</div>
		</div>
		<div class="content">
			<div class="title textline-2">{{ courseInfo.occname }}</div>
			<div class="time flex-ac">
				<img src="../../assets/image/icon-clock.png" alt="" class="icon" />
				<span>{{ courseInfo.occstarttime }}</span>
			</div>
			<div class="time flex-ac">
				<img src="../../assets/image/ls.png" alt="" class="icon" />
				<span>{{ courseInfo.teacher }}</span>
			</div>
			<div class="time flex-ac">
				<img src="../../assets/image/dd.png" alt="" class="icon" />
				<span>{{ courseInfo.address }}</span>
			</div>
			
			<div class="detail-btn">了解详情</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "courseListCell",
	props: {
		courseInfo: Object,
	},
	data() {
		return {};
	},
	components: {},

	computed: {},

	created() {},

	methods: {
		toToDetail() {
			this.$router.push({
				path: "/openClassInfo",
				query: {
					id: this.courseInfo.id,
					title: this.courseInfo.occname
				},
			});
		},
	},
};
</script>

<style scoped lang="less">
.cell-courseList {
	width: 282px;
	height: 360px;
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.2);
	margin-bottom: 20px;
	.cover-wrap {
		height: 158px;
		position: relative;
		border-radius: 8px 8px 0 0;
		overflow: hidden;
		.cover {
			width: 282px;
		}
		.status {
			width: 86px;
			height: 32px;
			line-height: 32px;
			padding-left: 12px;
			position: absolute;
			top: -4px;
			right: -3px;
			background-image: url("../../assets/image/tag-course-list-bg.png");
			background-repeat: no-repeat;
			background-size: 100%;
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			color: #ffffff;
		}
	}
	.content {
		padding: 10px;
		.title {
			width: 262px;
			// height: 52px;
			line-height: 26px;
			font-size: 16px;
			color: #545c63;
		}
		.time {
			margin: 10px 0;
			font-size: 14px;
			color: #9f9ea5;
			line-height: 17px;
			.icon {
				width: 17px;
				// height: 17px;
				margin-right: 10px;
			}
		}
		.info {
			.study-count {
				font-size: 14px;
				flex: 1;
				color: #9f9ea5;
			}
			.class-progress {
				font-size: 14px;
				color: #9f9ea5;

				.active {
					color: #f1201b;
				}
			}
		}
		.detail-btn {
			margin-top: 10px;
			margin-right: 3px;
			width: 88px;
			height: 28px;
			border: 1px solid #15abee;
			border-radius: 14px;
			float: right;
			font-size: 13px;
			line-height: 26px;
			color: #15abee;
			text-align: center;
			cursor: pointer;
			user-select: none;
			&:hover {
				border: none;
				line-height: 28px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				color: #fff;
			}
		}
	}
}
</style>
